<template>
  <div>
    <a-card title="物料开发">
      <a-row>
        <a-col :span="6" :md="8" :xl="6" style="max-width: 256px">
          <a-input-search
            v-model="searchForm.search"
            placeholder="物料编号/名称"
            allowClear
            @search="search"
          />
        </a-col>

        <a-col
          :span="6"
          :md="8"
          :xl="6"
          style="margin-left: 10px; max-width: 256px"
        >
          <a-select
            v-model="searchForm.status"
            placeholder="选择状态"
            allowClear
            style="width: 100%"
            @change="changeCategory"
          >
            <a-select-option
              v-for="(key, value) in statusItems"
              :key="key"
              :value="value"
              >{{ key }}
            </a-select-option>
          </a-select>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="detail(form)"
            >开发物料</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="image" slot-scope="value, record">
            <div>
              <!-- 首先检查数组是否存在且不为空 -->
              <div
                v-if="
                  record.goods_image_items &&
                  record.goods_image_items.length > 0
                "
              >
                <!-- 接下来检查第一项是否为特殊值 'no_good' -->
                <div v-if="record.goods_image_items[0] === 'no_good'">
                  未匹配到产品
                </div>
                <!-- 如果不是 'no_good'，则显示图片 -->
                <img
                  v-else
                  :src="record.goods_image_items[0].file"
                  alt="Preview"
                  @click="showImages(record.goods_image_items)"
                  style="width: 50px; cursor: pointer"
                />
              </div>
              <!-- 如果数组不存在或为空，也显示文本 -->
              <div v-else>未上传图片</div>
            </div>
          </template>

          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detail(item)">编辑</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>

      <!-- 图片浏览模态框 -->
      <a-modal
        title="Image Gallery"
        :visible="isModalVisible"
        @cancel="handleCancel"
        width="50%"
        footer="null"
      >
        <a-carousel effect="fade" v-model="currentImageIndex">
          <div v-for="(img, index) in images" :key="index">
            <img
              :src="img.file"
              style="width: 100%; height: auto"
              :alt="img.name"
            />
          </div>
        </a-carousel>
      </a-modal>
    </a-card>
  </div>
</template>
  
  <script>
import { materialsDevelopList } from "@/api/materials";

export default {
  name: "Develop",
  data() {
    return {
      statusItems: {
        concept: "待开发",
        in_development: "开发中",
        launched: "已发布",
        pending_review: "待审核",
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "开发编号",
          dataIndex: "number",
        },
        {
          title: "创建人",
          dataIndex: "create_user_name",
        },

        // {
        //   title: "Image",
        //   key: "image",
        //   scopedSlots: { customRender: "image" },
        // },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },

        {
          title: "状态",
          dataIndex: "status",
          customRender: (value, item) => {
            return this.statusItems[item.status];
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建日期",
          dataIndex: "create_time",
          width: 150,
        },
        {
          title: "更新日期",
          dataIndex: "update_time",
          width: 120,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 147,
        },
      ],
      searchForm: { page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      form: {},

      //  图片相关
      isModalVisible: false,
      images: [],
      currentImageIndex: 0,
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },

    list() {
      this.loading = true;
      materialsDevelopList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },

    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    detail(item) {
      console.log(item.id);
      this.$router.push({
        path: "/materials/dev_record_detail",
        query: { id: item.id },
      });
    },

    changeCategory() {
      this.list();
    },
    // 打开图片浏览模态框
    showImages(imageItems) {
      this.images = imageItems; // 设置所有图片
      this.currentImageIndex = 0; // 默认展示第一张图片
      this.isModalVisible = true; // 显示模态框
    },
    handleCancel() {
      this.isModalVisible = false; // 关闭模态框
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>